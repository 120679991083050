import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectInboundRoutes, selectOutboundRoutes, selectAllBreakfastRecords, selectAllLunchRecords, selectAllSnackRecords, selectAllRoutes, selectAllActiveVehicles, selectAllActiveDrivers, transRoutesSlice } from "./../../store";
import RoutesSection from "./RoutesSection";
import PersonnelSection from "./PersonnelSection";
import { AuthService, CustomerService, TransRoutesService } from "../../services";
import { PERSONAL_ROUTE_STATUS, ROUTE_STATUS, reportRootUrl } from "../../shared";
import BreakfastSection from './BreakfastSection';
import moment from 'moment';
import LunchSection from "./LunchSection";
import SnackSection from "./SnackSection";


const RoutesDashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { fetchAllRoutes, createRoute, fetchAllBreakfastRecords, fetchAllLunchRecords, fetchAllSnackRecords }  = transRoutesSlice.actions;
  const inboundRoutes = useSelector(selectInboundRoutes);
  const outboundRoutes = useSelector(selectOutboundRoutes);
  const allRoutes = useSelector(selectAllRoutes);
  const drivers = useSelector(selectAllActiveDrivers);
  const vehicles = useSelector(selectAllActiveVehicles);
  const breakfastRecords = useSelector(selectAllBreakfastRecords);
  const lunchRecords = useSelector(selectAllLunchRecords);
  const snackRecords = useSelector(selectAllSnackRecords);
  const [showCopyDateLoading, setShowCopyDateLoading] = useState(false);
  const [showSyncCustomersLoading, setShowSyncCustomersLoading] = useState(false);
  const now = new Date();
  const yesterday = new Date();
  const tomorrow = new Date();
  yesterday.setDate(now.getDate() - 1);
  tomorrow.setDate(now.getDate() + 1);
  const getDateString = (date) => {
    return ((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) + '/' + ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate())) + '/' + date.getFullYear()
  }
  const directToSchedule = () => {
    navigate(`/trans-routes/schedule?dateSchedule=${moment(tomorrow).format('YYYY-MM-DD')}`);
  }
  const generateRouteReport = () => {
    window.open(`${reportRootUrl}?token=${localStorage.getItem('token')}&date=${getDateString(now)}`, '_blank');
  }
  const goToHistoryPage = () => {
    navigate('/trans-routes/history');
  }
  const goToSignature = () => {
    navigate('/trans-routes/route-signature');
  }
  const createNewRoute = () => {
    navigate('/trans-routes/create')
  }
  const copyYesterdayRoutes = () => {
    setShowCopyDateLoading(true);
    TransRoutesService.getAll(getDateString(yesterday)).then(({data: yesterdayRoutes}) => {
      for (const draftRoute of yesterdayRoutes) {
        if (draftRoute) {
          const data = Object.assign({}, {
            name: draftRoute.name,
            schedule_date: ((now.getMonth() > 8) ? (now.getMonth() + 1) : ('0' + (now.getMonth() + 1))) + '/' + ((now.getDate() > 9) ? now.getDate() : ('0' + now.getDate())) + '/' + now.getFullYear(),
            vehicle: draftRoute.vehicle,
            driver: draftRoute.driver,
            type: draftRoute.type,
            start_mileage: vehicles.find((vehicle) => vehicle.id === draftRoute.vehicle)?.mileage,
            route_customer_list: draftRoute.route_customer_list?.map((customer) => {
              return Object.assign({}, customer, {
                customer_enter_center_time: null,
                customer_leave_center_time: null,
                customer_pickup_time: null,
                customer_dropoff_time: null,
                customer_estimated_pickup_time: null,
                customer_estimated_dropoff_time: null,
                customer_route_status: PERSONAL_ROUTE_STATUS.NO_STATUS
              })
            })
          });
          dispatch(createRoute({ data }));
        }
      }
      setTimeout(() => {
        dispatch(fetchAllRoutes());
        setShowCopyDateLoading(false);
      }, 2000);
    });
  }

  const cleanAllRoutesStatus = () => {
    for (const route of allRoutes) {
      let cleanRoute = Object.assign({}, route, { status: [ROUTE_STATUS.NOT_STARTED] });
      let newCustomers = [];
      for (let i=0; i< cleanRoute.route_customer_list.length; i++) {
        const newCustomerListObject = {
          ...cleanRoute.route_customer_list[i],
          customer_enter_center_time: null,
          customer_leave_center_time: null,
          customer_pickup_time: null,
          customer_dropoff_time: null,
          customer_estimated_pickup_time: null,
          customer_estimated_dropoff_time: null,
          customer_route_status: cleanRoute.route_customer_list[i].customer_route_status === PERSONAL_ROUTE_STATUS.SCHEDULED_ABSENT ? PERSONAL_ROUTE_STATUS.SCHEDULED_ABSENT : PERSONAL_ROUTE_STATUS.NO_STATUS
        }
        newCustomers.push(newCustomerListObject);
      }
      cleanRoute = Object.assign({}, cleanRoute, {route_customer_list: newCustomers});
      TransRoutesService.updateRoute(route.id, cleanRoute);
    }
    setTimeout(dispatch(fetchAllRoutes()), 10000);
  }
  const syncCustomersInfo = () => {
    setShowSyncCustomersLoading(true);
    CustomerService.getAllCustomers().then(data => {
      const customers = data.data;
      const customersMap = new Map();
      for (const customer of customers) {
        customersMap.set(customer.id, {
          customer_name: `${customer.name} ${customer.name_cn?.length > 0 ? `(${customer.name_cn})` : ``}`,
          customer_type: customer.type,
          customer_pickup_status: customer.pickup_status,
          customer_note: customer.note,
          customer_special_needs: customer.special_needs,
          customer_phone: customer.phone || customer.mobile_phone || customer.home_phone,
          customer_table_id: customer.table_id,
          customer_language: customer.language
        })
      };
      for (const route of allRoutes) {
        const customerList = route.route_customer_list;
        const newCustomerList = customerList.map((customerItem) => Object.assign({}, customerItem, customersMap.get(customerItem.customer_id)));
        const newRouteObject = Object.assign({}, route, {route_customer_list: newCustomerList});
        TransRoutesService.updateRoute(route.id, newRouteObject);
      }
      setTimeout(() => { dispatch(fetchAllRoutes()); setShowSyncCustomersLoading(false);}, 5000);
    })
  }
  const confimHasBreakfast = (customer) => {
		TransRoutesService.createBreakfastRecords({
			customer_id: customer?.customer_id,
			customer_name: customer?.customer_name,
			has_breakfast: true,
			date: moment(new Date()).format('MM/DD/YYYY'),
			create_by: localStorage.getItem('user') && JSON.parse(localStorage.getItem('user'))?.name,
			create_date: new Date(),
			edit_history: [{
				employee: localStorage.getItem('user') && JSON.parse(localStorage.getItem('user'))?.name,
				date: new Date()
			}]
		}).then(() => {
			dispatch(fetchAllBreakfastRecords());
		})
	}

  const confirmHasLunch = (customer) => {
		TransRoutesService.createLunchRecords({
			customer_id: customer?.customer_id,
			customer_name: customer?.customer_name,
			has_lunch: true,
			date: moment(new Date()).format('MM/DD/YYYY'),
			create_by: localStorage.getItem('user') && JSON.parse(localStorage.getItem('user'))?.name,
			create_date: new Date(),
			edit_history: [{
				employee: localStorage.getItem('user') && JSON.parse(localStorage.getItem('user'))?.name,
				date: new Date()
			}]
		}).then(() => {
			dispatch(fetchAllLunchRecords());
		})
	}

  const confirmHasSnack = (customer) => {
		TransRoutesService.createSnackRecords({
			customer_id: customer?.customer_id,
			customer_name: customer?.customer_name,
			has_snack: true,
			date: moment(new Date()).format('MM/DD/YYYY'),
			create_by: localStorage.getItem('user') && JSON.parse(localStorage.getItem('user'))?.name,
			create_date: new Date(),
			edit_history: [{
				employee: localStorage.getItem('user') && JSON.parse(localStorage.getItem('user'))?.name,
				date: new Date()
			}]
		}).then(() => {
			dispatch(fetchAllSnackRecords());
		})
	}

	const removeBreakfastRecord = (customer_id) => {
    const breakfast = breakfastRecords.find(b => b.customer_id === customer_id)?.id;
		TransRoutesService.deleteBreakfastRecords(breakfast).then(() => {
			dispatch(fetchAllBreakfastRecords());
		})
	}
  const removeLunchRecord = (customer_id) => {
    const lunch = lunchRecords.find(b => b.customer_id === customer_id)?.id;
		TransRoutesService.deleteLunchRecords(lunch).then(() => {
			dispatch(fetchAllLunchRecords());
		})
	}
  const removeSnackRecord = (customer_id) => {
    const snack = snackRecords.find(b => b.customer_id === customer_id)?.id;
		TransRoutesService.deleteSnackRecords(snack).then(() => {
			dispatch(fetchAllSnackRecords());
		})
	}
  return (
    <>
      <div className="list row">
        <div className="col-md-4 text-primary mb-2 ps-0">
          <h5>Today's Route Status</h5>
        </div>
      </div>
      <div className="list row mb-4">
        <div className=" text-primary mb-2">
          Today's Date: { new Date().toLocaleDateString() }
        </div>
        <div className="text-primary mb-2">
          <button type="button" className="btn btn-primary btn-sm me-1 mb-2" onClick={()=> syncCustomersInfo()}>{showSyncCustomersLoading? 'Loading...' : `Sync Customers Data`}</button>
          <button type="button" className="btn btn-primary btn-sm me-1 mb-2" onClick={()=> generateRouteReport()}>Generate Route Report</button>
          {AuthService.canAddOrEditRoutes() &&
            <button type="button" className="btn btn-primary btn-sm me-1 mb-2" onClick={() => directToSchedule()}>Plan Tomorrow's Route Schedule</button>
          }
          <button type="button" className="btn btn-primary btn-sm me-1 mb-2" onClick={()=> createNewRoute()}>Create New Route</button>
          <button type="button" className="btn btn-primary btn-sm me-1 mb-2" onClick={()=> copyYesterdayRoutes()}>{showCopyDateLoading? 'Loading...' : `Copy Yesterday Routes`}</button>
          <button type="button" className="btn btn-secondary btn-sm me-1 mb-2" onClick={()=> goToHistoryPage()}>View History</button>
          <button type="button" className="btn btn-danger btn-sm me-1 mb-2" onClick={()=> cleanAllRoutesStatus()}>Clean All Routes Status</button>
          <button type="button" className="btn btn-secondary btn-sm me-1 mb-2" onClick={()=> goToSignature()}>View Routes Signature</button>
        </div>
      </div>
      <div className="list row">
        <div className="col-md-12 mb-4">
          <RoutesSection transRoutes={inboundRoutes} drivers={drivers} vehicles={vehicles} sectionName="Inbound Routes"/>
        </div>
        <hr />
        <div className="col-md-12 mb-4">
          <RoutesSection transRoutes={outboundRoutes} drivers={drivers} vehicles={vehicles} sectionName="Outbound Routes"/>
        </div>
        <hr />
      </div>
      <div className="list row">
        <div className="col-md-4 col-sm-12 mb-4">
          <BreakfastSection transRoutes={allRoutes} breakfastRecords={breakfastRecords} confimHasBreakfast={confimHasBreakfast} removeBreakfastRecord={removeBreakfastRecord} sectionName={'Breakfast Info'}/>
        </div>
        <div className="col-md-4 col-sm-12 mb-4">
          <LunchSection transRoutes={allRoutes} lunchRecords={lunchRecords} confirmHasLunch={confirmHasLunch} removeLunchRecord={removeLunchRecord} sectionName={'Lunch Info'}/>
        </div>
        <div className="col-md-4 col-sm-12 mb-4">
          <SnackSection transRoutes={allRoutes} snackRecords={snackRecords} confirmHasSnack={confirmHasSnack} removeSnackRecord={removeSnackRecord} sectionName={'Snack Info'}/>
        </div>
        <hr/>
      </div>
      <div className="list row">
        <div className="col-md-12 mb-4">
          <PersonnelSection transRoutes={allRoutes} showCompletedInfo={false} showGroupInfo={false} allowForceEdit={AuthService.canAddOrEditRoutes()} showFilter={true} sectionName="Personnel Status (click on each user to edit)" vehicles={vehicles}/>
        </div>
      </div>
    </>
    
  );
};

export default RoutesDashboard;