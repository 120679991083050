import moment from "moment";
import http from "../http-common";

const getAllEvents = (params) => {
  return http.get('/events', { params});
};

const getByCustomer = (params) => {
  return http.get('/events/getByCustomer', {params})
};

const createNewEvent = (data) => {
  data.status = 'active';
	return http.post('/events', data);
};


const updateEvent = (id, data) => {
  return http.put(`/events/${id}`, data);
}

const disableEvent = (id, data) => {
  return http.put(`/events/${id}/disable`, data);
}

const getEvent = (id) => {
  return http.get(`/events/${id}`);
}

const deleteEvent = (id) => {
  return http.delete(`/events/${id}`)
}

const assignTransportationToEvents = (data) => {
  return http.post(`/events/assign`, data);
}

const formatDate = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const localDate = `${year}-${month}-${day}`;
  return localDate;
};

const getTimeData = () => {
  return http.get(`/timedata/get-by-condition1`, {
      params: {
        date: moment(new Date()).format('YYYY-MM-DD')
      }
    });
}

const getTransportationInfo = (eventsList, item, timeDocs = []) => {
  const today = new Date();
  // const timeDocs = (await http.get(`/timedata/get-by-condition1`, {
  //   params: {
  //     date: moment(today).format('YYYY-MM-DD')
  //   }
  // }))?.data;
  
  const futureEvents = eventsList.filter((d) => new Date(d.start_time) >= new Date(today.toLocaleDateString()) );
  const isFutureEvent = (event) => futureEvents.find((fe) => fe.id === event.id);
  const futureEventsGroupMap = new Map();
  for (const e of futureEvents) {
    const eDateString = new Date(e.start_time).toLocaleDateString();
    if (futureEventsGroupMap.has(eDateString)) {
      futureEventsGroupMap.set(eDateString, [...futureEventsGroupMap.get(eDateString), e])
    } else {
      futureEventsGroupMap.set(eDateString, [e]);
    }
  }
  return {
    maxTranslate1: timeDocs.find(timeDoc => moment(timeDoc.time).format('YYYY-MM-DD') == moment(item.start_time).format('YYYY-MM-DD'))?.translate1_number || 0,
    maxTranslate2: timeDocs.find(timeDoc => moment(timeDoc.time).format('YYYY-MM-DD') == moment(item.start_time).format('YYYY-MM-DD'))?.translate2_number || 0,
    maxResource: timeDocs.find(timeDoc => moment(timeDoc.time).format('YYYY-MM-DD') == moment(item.start_time).format('YYYY-MM-DD'))?.max_resource_number || 0,
    totalTranslate1: futureEventsGroupMap.get(new Date(item?.start_time).toLocaleDateString())?.filter(e => e.interpreter === '1(译)')?.length,
    totalTranslate2: futureEventsGroupMap.get(new Date(item?.start_time).toLocaleDateString())?.filter(e => e.interpreter === '2(译)')?.length,
    totalResource: [... new Set(futureEventsGroupMap.get(new Date(item?.start_time).toLocaleDateString())?.map(e => e.source_uuid))]?.length,
    isFutureEvent: isFutureEvent(item)
  }
}

// const baseUrl = (window.location.hostname.includes('ws1') ||window.location.hostname.includes('localhost') || window.location.hostname.includes('site1')) ? "https://ws1.mayosolution.com/api" : ((window.location.hostname.includes('ws3') || window.location.hostname.includes('site3')) ? "https://ws3.mayosolution.com/api" : "https://ws2.mayosolution.com/api")
const baseUrl = (window.location.hostname.includes('worldshine.mayo.llc') ||window.location.hostname.includes('worldshine1') || window.location.hostname.includes('site1')) ? "https://worldshine.mayo.llc/api" : ((window.location.hostname.includes('worldshine3') || window.location.hostname.includes('site3')) ? "https://worldshine3.mayo.llc/api" : "https://worldshine2.mayo.llc/api")

// const site = (window.location.hostname.includes('ws1') ||window.location.hostname.includes('localhost') || window.location.hostname.includes('site1')) ? 1 : ((window.location.hostname.includes('ws3') || window.location.hostname.includes('site3')) ? 3 : 2)
const site = (window.location.hostname.includes('worldshine.mayo.llc') ||window.location.hostname.includes('worldshine1') || window.location.hostname.includes('site1')) ? 1 : ((window.location.hostname.includes('worldshine3') || window.location.hostname.includes('site3')) ? 3 : 2)

const generatePdf = (data) => {
  window.open(`${baseUrl}/docs/get-pdfs?docTemplateName=med_notification&inputData=${encodeURIComponent(JSON.stringify(data))}`);
}

const generateSheet = (data) => {
  window.open(`${baseUrl}/sheets/get-sheets?excelTemplateName=visit_record_sheet&inputData=${encodeURIComponent(JSON.stringify(data))}`);
}

const generateDoc = (data) => {
  window.open(`${baseUrl}/docs/get-docs?docTemplateName=med_notification&inputData=${encodeURIComponent(JSON.stringify(data))}`);
}

const generateSheetPDF = (data) => {
  window.open(`${baseUrl}/sheets/get-pdf?excelTemplateName=visit_record_sheet&inputData=${encodeURIComponent(JSON.stringify(data))}`);
}

export const EventsService = {
  createNewEvent,
  getAllEvents,
  updateEvent,
  disableEvent,
  getEvent,
  deleteEvent,
  assignTransportationToEvents,
  formatDate,
  getTransportationInfo,
  generatePdf,
  generateSheet,
  generateDoc,
  generateSheetPDF,
  getTimeData,
  getByCustomer,
  site
};
